.container {
	display: flex;
	justify-content: space-between;
	padding: 50px 20px;
	color: var(--gray);
	gap: 50px;
	/* width: 80%; */
	margin: 0 auto;
	gap: 100px;
}
.mainContainer {
	background-color: var(--background);
	padding: 0 20px;
	margin-top: 100px;
}

.nav__item {
	font-size: 18px;
}
.links {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	/* flex: 1; */
	gap: 10px;
	align-items: center;
	text-align: right;
}

.links a {
	width: 100%;
	text-align: left;
}
.links a:hover {
	color: var(--brand);
}
.some {
	width: 100%;
	display: flex;
	flex-direction: row;
	gap: 20px;
	flex: 1;
}

.some a {
	width: 30px;
}
.some a:hover {
	color: var(--brand);
}

/* // tablet */
@media (max-width: 640px) {
	.container {
		flex-direction: column;
		gap: 50px;
	}
	.links {
		align-items: flex-start;
	}
}
.address {
	display: flex;
	gap: 20px;
	flex-direction: column;
}
.addressDetail {
	display: flex;
	gap: 5px;
	flex-direction: column;
}
/* // screen more than 769 will have 80% */
@media screen and (min-width: 769px) {
	.container {
		width: 80%;
		padding: var(--size-medium) 0;
	}
}

/* // GREATER THAN 1920 PX  */
@media (min-width: 1921px) {
	.container {
		width: 1600px !important;
		margin: 0 auto;
		padding-left: 20px;
		padding-right: 20px;
	}
	.links {
		flex: 0.6;
	}
}

/* // GREATER THAN 2920 PX  */
@media (min-width: 2921px) {
	.mainContainer {
		font-size: 34px;
	}

	.nav__item {
		font-size: 34px;
	}
}
